// @ts-nocheck

// External imports
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

// Material UI components
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Rating from '@mui/material/Rating';
import Slider from '@mui/material/Slider';
import Snackbar from '@mui/material/Snackbar';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Material UI icons
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

// Material UI styles
import { alpha } from '@mui/material/styles';
import { green } from '@mui/material/colors';

// Internal components
import Fancybox from './FancyBox';
import NavigationBar from './NavigationBar';
import apiUrl from '../apiUrl';

const SnackPage = () => {
  const { t } = useTranslation();

  const dataToNavigationbar = {
    snackPageComponent: '',
  }

  const [snackData, setSnackData] = useState(null);
  const { snackId } = useParams(); // Use useParams to access URL parameters

  const fileInputRef = useRef(null);
  const initialFormValues = { snackType: '', productName: '', rating: 3, image: null, comment: '' };
  const formRef = useRef(null);
  const { isLoggedIn } = useAuth(); // Access the login status from context
  const navigate = useNavigate();

  const [reviewsData, setReviewsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [formValues, setFormValues] = useState({ rating: 3, image: null, comment: '' });
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [loadingSnackInfo, setLoadingSnackInfo] = useState(true);
  const [newItemLoading, setNewItemLoading] = useState(false);
  const [reviewsNotFound, setReviewsNotFound] = useState(false);
  const [showReviewAddSuccessfullSnackbar, setReviewAddSuccessfullSnackbar] = useState(false);
  const [ImageFilesizeError, setImageFilesizeError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 30;

  // Get current reviews
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviewsData.slice(indexOfFirstReview, indexOfLastReview);

  const fetchSnackData = async () => {
    try {
      const response = await fetch(`${apiUrl}/snackdetails/${snackId}`);
      if (!response.ok) {
        setLoadingComponent(false);
        setLoadingSnackInfo(false);
        throw new Error('Failed to fetch snack data');
      }
      const data = await response.json();
      setSnackData(data);
      fetchReviews();
      setLoadingComponent(false);
      setLoadingSnackInfo(false);
    } catch (error) {
      console.error('Error fetching snack data:', error);
      // Handle error (e.g., display an error message)
    }
  };

  const fetchReviews = async () => {
    try {
      const response = await fetch(`${apiUrl}/reviews/${snackId}`);
      if (response.status === 204) {
        setLoadingReviews(false);
        setReviewsNotFound(true);
      } else if (response.ok) {
        const data = await response.json();
        setReviewsData(data);
        setLoadingReviews(false);
        setReviewsNotFound(false);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('There was a problem fetching the data:', error);
      setLoadingReviews(false);
    }
  };

  // Fetch data based on the ID when the component mounts
  useEffect(() => {
    fetchSnackData(); // Call the fetch function
  }, [snackId]); // Re-run effect when the snackId changes

  const handleDialogOpen = () => {
    setOpenDialog(true);
    formValues.image = null;
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    // Clear the selected image by resetting the input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSliderChange = (event, newValue) => {
    setFormValues({ ...formValues, rating: newValue });
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const checkLoginStatus = () => {
    const token = sessionStorage.getItem('token');
    return !!token; // Returns true if token exists, otherwise false
  };

  const handleImageInputChange = (event) => {
    const { name, files } = event.target;

    if (files.length > 0) {
      const file = files[0];

      // Check if the selected file is an image
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();

        // When the file is loaded, draw it to a canvas and compress it
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas width and height to match the original image dimensions
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image to the canvas at its original size
            ctx.drawImage(img, 0, 0);

            // Compress the image by converting it to a Blob (JPEG with quality 0.8)
            canvas.toBlob(
              (blob) => {
                // Create a new File object with the compressed image
                const compressedImage = new File([blob], file.name, { type: 'image/jpeg' });

                // Update the form state with the compressed image
                setFormValues((prevFormValues) => ({
                  ...prevFormValues,
                  [name]: compressedImage, // Set the compressed image as the value
                }));
              },
              'image/jpeg',
              0.8 // Adjust the compression quality here (0.8 means 80% quality)
            );
          };
        };

        // Read the file as a Data URL (to use in the img.src)
        reader.readAsDataURL(file);
      } else {
        console.error('Selected file is not an image.');
      }
    }
  };

  const sendNewReview = async (event) => {
    event.preventDefault();
    setNewItemLoading(true);
    try {
      const data = new FormData(event.currentTarget);

      // Append the compressed image data if it exists
      if (formValues.image) {
        data.set('image', formValues.image, formValues.image.name);
      }

      // Send the request with credentials to include cookies
      const response = await fetch(`${apiUrl}/newreview/${snackId}`, {
        method: 'POST',
        body: data,
        credentials: 'include', // Include cookies for authentication
      });

      if (response.ok) {
        try {
          await Promise.all([fetchSnackData(), fetchReviews()]);
          setOpenDialog(false);
          setNewItemLoading(false);
          setFormValues(initialFormValues);
          setReviewAddSuccessfullSnackbar(true);
        } catch (error) {
          console.error('Error fetching snack data or reviews:', error);
        }
      } else {
        console.error('Failed to add review');
      }
    } catch (error) {
      console.error('Error adding review:', error);
    } finally {
      setNewItemLoading(false); // Set loading to false regardless of success/error
    }
  };

  return (
    <>
      <NavigationBar navigationBarData={dataToNavigationbar} />
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px', marginTop: { xs: '58px', md: '64px' } }}>
        {loadingComponent ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2} sx={{ maxWidth: '1100px' }}>
            <Grid item xs={12} sm={4}>
              <Typography
                fontSize={'0.7em'}
                color={alpha('#000000', 0.5)}
                component="div"
              >
                {t('timestamp_text')} {new Date(snackData[0].timeCreated).toLocaleDateString('en-GB')}
              </Typography>
              <Typography
                variant="body1"
                component="div"
              >
                {snackData[0].productBrand}
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: '600'
                }}>
                {snackData[0].productName}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  sx={{
                    fontSize: '0.8em',
                    marginRight: '0.5em'
                  }}
                  color={alpha('#000000', 0.5)}
                  component="div"
                >
                  {t('average_rating_info_text')}
                </Typography>
                <Typography>
                  {snackData[0].averageRating.toFixed(1)} {/* Display average rating with one decimal place */}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column', // Change flex direction to column
                  marginBottom: '1em',
                  '@media (min-width: 955px)': { // Media query for screen width greater than or equal to 955px
                    flexDirection: 'row', // Reset flex direction to row
                    alignItems: 'end',
                  },
                  '@media (max-width: 599px)': { // Media query for screen width less than 600px
                    flexDirection: 'row', // Change flex direction to row
                  },
                }}
              >
                <Rating
                  name='rating'
                  value={snackData[0].averageRating}
                  precision={0.1}
                  max={5}
                  size='large'
                  readOnly
                />
                <Typography
                  sx={{
                    flexGrow: 1,
                    alignContent: 'center',
                    fontSize: '0.8em',
                    marginLeft: '0.3em'
                  }}
                  color={alpha('#000000', 0.5)}
                  component="div"
                >
                  ({reviewsData.length})
                </Typography>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => navigate(-1)} // Navigate to the previous page
                  startIcon={<ArrowBackOutlinedIcon />}
                  sx={{
                    color: '#D97706',
                    border: '1px solid',
                    textTransform: 'none',
                    '@media (max-width: 955px)': { // Media query for screen width less than 955px
                      marginTop: '1em'
                    },
                    '@media (max-width: 599px)': { // Media query for screen width less than 600px
                      marginTop: 0
                    },
                  }}
                >
                  {t('back_button')}
                </Button>
              </Box>
              <Divider sx={{ marginBottom: '1em' }} />

              {/* Product image */}
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },

                }}
              >
                <a data-fancybox='gallery' href={`${apiUrl}${snackData[0].imageUrl}`}>
                  <Box
                    component='img'
                    src={`${apiUrl}${snackData[0].imageUrl}`}
                    alt='User added snack'
                    sx={{
                      maxWidth: '100%',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      transition: 'transform 0.1s',
                      '&:hover': {
                        transform: 'scale(1.0175)',
                      },
                    }}
                  />
                </a>
              </Fancybox>
              <Divider sx={{ marginTop: '1em' }} />
            </Grid>

            {/* Reviews */}
            <Grid item xs={12} sm={8} sx={{ height: 'fit-content', paddingBottom: '80px' }}>
              {loadingReviews ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  {reviewsNotFound ? (
                    <Alert variant='filled' severity="info" sx={{ marginTop: '1rem' }}>
                      {t('reviews_not_found_info')}
                    </Alert>
                  ) : (
                    <>
                      {reviewsData
                        .sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated)) // Sort all reviews by timeCreated in descending order
                        .slice((currentPage - 1) * reviewsPerPage, currentPage * reviewsPerPage) // Paginate the sorted reviews
                        .map((review, index) => (
                          <Card key={index} sx={{ marginBottom: '1em' }}>
                            <CardContent>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                  <Typography fontSize={'0.7rem'} color={alpha('#000000', 0.5)} component="div">
                                    {t('timestamp_text')} {new Date(review.timeCreated).toLocaleDateString('en-GB')}
                                  </Typography>
                                  <Typography variant="h6" component="div">
                                    {review.username}
                                  </Typography>
                                  <Rating
                                    name={`rating-${index}`}
                                    value={review.rating}
                                    precision={0.5}
                                    max={5}
                                    size='medium'
                                    readOnly
                                  />
                                </Box>

                                {/* FancyBox thumbnail */}
                                {review.imageUrl && (
                                  <Fancybox
                                    options={{
                                      Carousel: {
                                        infinite: false,
                                      },
                                    }}
                                  >
                                    <a
                                      data-fancybox="gallery"
                                      href={`${apiUrl}${review.imageUrl.replace('_100-review.webp', '_o-review.webp')}`} // Use _o-review.webp for the larger image in the carousel
                                    >
                                      <Box
                                        component='img'
                                        src={`${apiUrl}${review.imageUrl}`} // Use the _100-review.webp image for the thumbnail
                                        alt='User added snack'
                                        sx={{
                                          height: '70px',
                                          width: '70px',
                                          objectFit: 'cover',
                                          borderRadius: '8px',
                                          overflow: 'hidden',
                                          transition: 'transform 0.1s', // Smooth hover effect
                                          '&:hover': {
                                            transform: 'scale(1.05)', // Scale up on hover
                                          },
                                        }}
                                      />
                                    </a>
                                  </Fancybox>
                                )}

                              </Box>
                              {review.comment && (
                                <>
                                  <Divider sx={{ margin: '1em 0 1em' }} />
                                  <Typography>{review.comment}</Typography>
                                </>
                              )}
                            </CardContent>
                          </Card>
                        ))}
                      {reviewsData.length > reviewsPerPage && (
                        <Pagination
                          count={Math.ceil(reviewsData.length / reviewsPerPage)}
                          page={currentPage}
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                          sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center' }}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        )}

        {isLoggedIn &&
          <>
            <div style={{ marginBottom: '80px' }} />
            <Fab onClick={handleDialogOpen} aria-label="add"
              sx={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                backgroundColor: '#D97706',
                '&:hover': {
                  backgroundColor: '#B45309', // Change to a darker shade for hover
                },
              }}
            >
              <AddOutlinedIcon sx={{ color: '#FFFBEB' }} />
            </Fab>
          </>
        }
        <Dialog open={openDialog} onClose={handleDialogClose} maxWidth={'xs'} fullWidth={true}>
          <DialogTitle>{t('add_new_review_modal_header')}</DialogTitle>
          <DialogContent>
            <Box component="form" ref={formRef} onSubmit={sendNewReview} noValidate>
              <TextField
                fullWidth
                multiline
                minRows={4}
                placeholder={t('add_optional_comment_placeholder')}
                name="comment"
                value={formValues.comment || ''} // Ensure a default value
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    [e.target.name]: e.target.value,
                  }))
                }
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#D97706', // Border color when focused
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#D97706', // Label color when focused
                  },
                }}
              />
              <Typography id="rating-slider" gutterBottom sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                {t('rating_text')}
                <Rating
                  value={formValues.rating}
                  precision={0.5}
                  max={5}
                />
              </Typography>
              <Slider
                name='rating'
                min={1}
                max={5}
                step={0.5}
                aria-label="rating-slider"
                sx={{ my: 1, color: '#D97706' }}
                defaultValue={3}
                onChange={handleSliderChange}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', my: 1 }}>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  name='image'
                  onChange={handleImageInputChange}
                />
                {formValues.image ? (
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', height: '40px' }}>
                    <Typography component='span'>{t('add_image_label')}</Typography>
                    <label htmlFor="file-upload">
                      <IconButton
                        component="span"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          width: '40px',
                          height: '40px',
                          marginLeft: '0.5rem',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        <TaskAltTwoToneIcon sx={{ color: green[500] }} />
                      </IconButton>
                    </label>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', height: '40px' }}>
                    <Typography component='span'>{t('add_image_label')}</Typography>
                    <label htmlFor="file-upload">
                      <IconButton
                        component="span"
                        sx={{
                          borderRadius: '8px',
                          width: '40px',
                          height: '40px',
                          marginLeft: '0.5rem',
                          backgroundColor: '#f5f5f5',
                          '&:hover': {
                            backgroundColor: '#e0e0e0',
                          },
                        }}
                      >
                        <AttachFileIcon />
                      </IconButton>
                    </label>
                  </Box>
                )}
              </Box>
              <DialogActions sx={{ padding: '1rem 0 0 0' }}>
                <Button onClick={handleDialogClose} sx={{ color: '#D97706' }}>{t('cancel_button')}</Button>
                <Button type='submit' variant="contained" autoFocus sx={{ backgroundColor: '#D97706', color: '#FFFBEB' }}>
                  {t('save_button')}
                </Button>
              </DialogActions>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={newItemLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Box>
          </DialogContent>
        </Dialog>
        <Snackbar open={showReviewAddSuccessfullSnackbar} autoHideDuration={3000} onClose={() => setReviewAddSuccessfullSnackbar(false)}>
          <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
            {t('review_add_success_alert')}
          </Alert>
        </Snackbar>
        <Snackbar open={ImageFilesizeError} autoHideDuration={3000} onClose={() => setImageFilesizeError(false)}>
          <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
            {t('image_filesize_too_big_error')}
          </Alert>
        </Snackbar>
      </Box >
    </>
  );
};

export default SnackPage;
