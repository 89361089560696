// @ts-nocheck

// External imports
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

// Material UI components
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';

// Material UI icons
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from '@mui/material/IconButton';

// Material UI styles
import { alpha } from '@mui/material/styles';
import { green } from '@mui/material/colors';

// Internal components
import NavigationBar from './NavigationBar';
import apiUrl from '../apiUrl';


const MySnacks = () => {
    const { t } = useTranslation();

    const dataToNavigationbar = {
        myReviewsComponent: '',
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSnacks, setSelectedSnacks] = useState({ beverages: true, salty: true, sweets: true });
    const [snacksData, setSnacksData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1); // Track the current page
    const [isMoreData, setIsMoreData] = useState(true); // Track if more data is available
    const itemsPerPage = 16; // Number of snacks per page
    const observerElement = useRef(null); // Ref for IntersectionObserver

    const formRef = useRef(null);
    const fileInputRef = useRef(null);
    const initialFormValues = { snackType: '', productBrand: '', productName: '', rating: 3, image: null, comment: '' };
    const [openFabDialog, setOpenFabDialog] = useState(false);
    const [formValues, setFormValues] = useState({ snackType: '', productBrand: '', productName: '', rating: 3, image: null, thumbnail: null, comment: '' });
    const [formErrors, setFormErrors] = useState({ snackType: '', productBrand: '', productName: '', image: '' });
    const [newItemLoading, setNewItemLoading] = useState(false);
    const [snacksNotFound, setSnacksNotFound] = useState(false);
    const [formSubmitFailure, setFormSubmitFailure] = useState(false);
    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

    const navigate = useNavigate(); // Get the navigate function

    const filteredSnacks = useMemo(() => {
        return snacksData.filter(snack => {
            if (selectedSnacks.beverages && snack.snackType === 'beverage') return true;
            if (selectedSnacks.salty && snack.snackType === 'salty') return true;
            if (selectedSnacks.sweets && snack.snackType === 'sweet') return true;
            return false;
        });
    }, [snacksData, selectedSnacks]);

    const fetchSnacks = useCallback(async (page) => {
        // If snacks are already determined to be not found, stop further requests
        if (snacksNotFound) {
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/mysnacks?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include cookies in the request
            });

            if (response.ok) {
                const data = await response.json();

                if (data.length === 0 && page === 1) {
                    // If no data is returned and it's the first page, mark snacks as not found
                    setSnacksNotFound(true);
                    setIsMoreData(false); // Stop fetching more data
                } else {
                    setSnacksNotFound(false); // Snacks were found
                    setSnacksData(prev => [...prev, ...data]); // Append new data to the current state

                    // Stop fetching more data if fewer items are returned
                    if (data.length < itemsPerPage) {
                        setIsMoreData(false);
                    }
                }
            } else if (response.status === 401) {
                setIsMoreData(false); // No data received
                sessionStorage.setItem('loginToSeeMySnacks', 'true');
                // If unauthorized, redirect the user to the login page
                navigate('/login');
            }
        } catch (error) {
            console.error("Error fetching snacks:", error);
        }
        setLoading(false);
    }, [snacksNotFound, itemsPerPage, navigate]);

    // Load snacks for the current page when the component is first mounted or when the page changes
    useEffect(() => {
        fetchSnacks(page);
    }, [page, fetchSnacks]);

    const loadMoreSnacks = useCallback(() => {
        if (isMoreData && !loading && !snacksNotFound) {
            setPage(prevPage => prevPage + 1); // Increment the page to load more data
        }
    }, [isMoreData, loading, snacksNotFound]);

    // Use IntersectionObserver to detect scrolling to the bottom
    useEffect(() => {
        const currentObserverElement = observerElement.current;
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !snacksNotFound) {
                loadMoreSnacks(); // Load more snacks when user scrolls to the bottom
            }
        }, { threshold: 1.0 });

        if (currentObserverElement) {
            observer.observe(currentObserverElement);
        }

        return () => {
            if (currentObserverElement) {
                observer.unobserve(currentObserverElement);
            }
        };
    }, [loadMoreSnacks, snacksNotFound]);


    const handleDialogOpen = () => {
        setOpenDialog(true);
        formValues.image = null;
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setFormValues(initialFormValues);
        setFormErrors('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFabDialogOpen = () => {
        setOpenFabDialog(true);
    };

    const handleFabDialogClose = () => {
        setOpenFabDialog(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    };

    const handleImageInputChange = (event) => {
        const { name, files } = event.target;
        if (files.length > 0) {
            const file = files[0];
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        canvas.toBlob((blob) => {
                            const compressedImage = new File([blob], file.name, { type: 'image/jpeg' });
                            setFormValues((prevFormValues) => ({
                                ...prevFormValues,
                                [name]: compressedImage,
                            }));
                        }, 'image/jpeg', 0.8);
                    };
                };
                reader.readAsDataURL(file);
            } else {
                console.error('Selected file is not an image.');
            }
        }
    };

    const handleCheckboxChange = (event) => {
        setSelectedSnacks({
            ...selectedSnacks,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSliderChange = (event, newValue) => {
        setFormValues({ ...formValues, rating: newValue });
    };

    const validateForm = () => {
        const errors = {
            snackType: '',
            productBrand: '',
            productName: '',
            image: '',
        };
        if (formValues.snackType === '') {
            errors.snackType = t('snack_type_error');
        }
        if (formValues.productBrand.trim() === '') {
            errors.productBrand = t('snack_brand_error');
        }
        if (formValues.productName.trim() === '') {
            errors.productName = t('snack_name_error');
        }
        if (!formValues.image) {
            errors.image = t('snack_image_error');
        }
        setFormErrors(errors);
        return Object.values(errors).every((error) => error === '');
    };

    const sendNewSnackForm = async (event) => {
        event.preventDefault();
        setNewItemLoading(true);
        if (!validateForm()) {
            setNewItemLoading(false);
            return;
        }
        try {
            const data = new FormData(event.currentTarget);
            data.set('image', formValues.image, formValues.image.name);

            const response = await fetch(`${apiUrl}/newitem`, {
                method: 'POST',
                body: data,
                credentials: 'include', // Include cookies in the request
            });

            if (response.ok) {
                setOpenDialog(false);
                setNewItemLoading(false);
                setFormValues(initialFormValues);
                setFormSubmitSuccess(true);
                setSnacksData([]);
                fetchSnacks();
            } else if (response.status === 400) {
                setFormSubmitFailure(true);
            } else {
                console.error('Failed to add item');
            }
        } catch (error) {
            console.error('Error adding item:', error);
        } finally {
            setNewItemLoading(false);
        }
    };

    return (
        <>
            <NavigationBar navigationBarData={dataToNavigationbar} />
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px', marginTop: { xs: '58px', md: '64px' } }}>
                {loading && page === 1 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {snacksNotFound ? (
                            <Alert variant='filled' severity="info" sx={{ width: '100%', marginTop: '32px', backgroundColor: '#D97706' }}>
                                {t('snacks_not_found_info')}
                            </Alert>
                        ) : (
                            <Grid container spacing={2} sx={{ maxWidth: '1100px' }}>
                                {filteredSnacks.map((snack, index) => (
                                    <Grid item key={index} xs={6} sm={6} md={4}>
                                        <Card>
                                            <CardContent sx={{ padding: 0 }}>
                                                {snack.imageUrl && (
                                                    <CardMedia
                                                        component="img"
                                                        sx={{
                                                            height: '300px',
                                                            objectFit: 'cover', // Ensures the image covers the area without zooming excessively
                                                            width: '100%', // Ensure it adjusts to the container's width
                                                        }}
                                                        image={`${apiUrl}${snack.imageUrl}`}
                                                        title="Snack Image" />
                                                )}
                                                <Box sx={{ padding: '1rem 1rem 0 1rem', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                                    <Typography fontSize={'0.7rem'} color={alpha('#000000', 0.5)} component="div">
                                                        {t('timestamp_text')} {new Date(snack.timeCreated).toLocaleDateString('en-GB')}
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "1",
                                                            WebkitBoxOrient: "vertical",
                                                        }}
                                                    >
                                                        {snack.productBrand}
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "1",
                                                            WebkitBoxOrient: "vertical",
                                                            lineHeight: 1.2,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {snack.productName}
                                                    </Typography>
                                                    {/* Spacer to push content to the bottom */}
                                                    <Box sx={{ flexGrow: 1 }} />
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'baseline',
                                                                flexWrap: 'wrap', // Allow items to wrap when the viewport is narrow
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '0.8em',
                                                                    marginRight: '0.5em',
                                                                }}
                                                                color={alpha('#000000', 0.5)}
                                                                component="div"
                                                            >
                                                                {t('average_rating_info_text')}
                                                            </Typography>

                                                            <Typography component="div" sx={{ minWidth: '25px' }}>
                                                                {snack.averageRating.toFixed(1)}
                                                            </Typography>
                                                        </Box>

                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap', // Allow wrapping when the viewport is narrow
                                                                alignItems: 'center', // Ensure vertical alignment stays centered
                                                            }}
                                                        >
                                                            <Rating
                                                                name={`rating-${index}`}
                                                                value={snack.averageRating}
                                                                precision={0.5}
                                                                max={5}
                                                                size="medium"
                                                                readOnly />

                                                            <Typography
                                                                sx={{
                                                                    marginLeft: '0.3em',
                                                                    fontSize: '0.8em', // Keeps the size as specified, no other styling changes
                                                                }}
                                                                color={alpha('#000000', 0.5)}
                                                                component="div"
                                                            >
                                                                ({snack.reviewsAmount})
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                            <CardActions sx={{ padding: '1rem 1rem 1rem 1rem' }}>
                                                <Button size="small" variant='outlined' sx={{ border: '1px solid', color: '#D97706' }} component={Link} to={`/snackpage/${snack.id}`}>
                                                    {t('rating_card_open_button')}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                        )}
                        {/* Infinite Scroll - Loader */}
                        {isMoreData && (
                            <Box ref={observerElement} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                                <CircularProgress />
                            </Box>
                        )}

                        <div style={{ marginBottom: '160px' }} />
                        <Fab
                            onClick={handleDialogOpen}
                            aria-label="add"
                            sx={{
                                position: 'fixed',
                                bottom: '90px',
                                right: '20px',
                                backgroundColor: '#D97706',
                                '&:hover': {
                                    backgroundColor: '#B45309', // Change to a darker shade for hover
                                },
                            }}
                        >
                            <AddOutlinedIcon sx={{ color: '#FFFBEB' }} />
                        </Fab>
                        <Fab
                            onClick={handleFabDialogOpen}
                            aria-label="add"
                            sx={{
                                position: 'fixed',
                                bottom: '20px',
                                right: '20px',
                                backgroundColor: '#D97706',
                                '&:hover': {
                                    backgroundColor: '#B45309', // Change to a darker shade for hover
                                },
                            }}
                        >
                            <TuneIcon sx={{ color: '#FFFBEB' }} />
                        </Fab>
                    </Box>
                )}

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={'xs'} fullWidth={true}>
                    <DialogTitle sx={{ paddingBottom: 0 }}>{t('add_new_snack_modal_header')}</DialogTitle>
                    <DialogContent>
                        <Box component="form" ref={formRef} onSubmit={sendNewSnackForm} noValidate>
                            <FormControl
                                fullWidth
                                sx={{
                                    margin: '1em 0 1em',
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#D97706', // Border color when focused
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#D97706', // Label color when focused
                                    },
                                }}
                                error={!!formErrors.snackType}
                            >
                                <InputLabel id="snack-type-label">{t('snack_type_placeholder')}</InputLabel>
                                <Select
                                    labelId="snack-type-label"
                                    id="snack-type-select"
                                    name="snackType"
                                    value={formValues.snackType}
                                    label={t('snack_type_placeholder')}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <MenuItem value="beverage">{t('dropdown_menu_items_beverages')}</MenuItem>
                                    <MenuItem value="salty">{t('dropdown_menu_items_salty')}</MenuItem>
                                    <MenuItem value="sweet">{t('dropdown_menu_items_sweets')}</MenuItem>
                                </Select>
                                <FormHelperText>{formErrors.snackType}</FormHelperText>
                            </FormControl>

                            <TextField
                                fullWidth
                                label={t('snack_brand_placeholder')}
                                name="productBrand"
                                required
                                error={!!formErrors.productBrand}
                                value={formValues.productBrand}
                                helperText={formErrors.productBrand}
                                onChange={handleInputChange}
                                sx={{
                                    marginBottom: '1em',
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#D97706', // Border color when focused
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#D97706', // Label color when focused
                                    },
                                }}
                            />

                            <TextField
                                fullWidth
                                label={t('snack_name_placeholder')}
                                name='productName'
                                required
                                error={!!formErrors.productName}
                                value={formValues.productName}
                                helperText={formErrors.productName}
                                onChange={handleInputChange}
                                sx={{
                                    marginBottom: '1em',
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#D97706', // Border color when focused
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#D97706', // Label color when focused
                                    },
                                }} />

                            <input
                                ref={fileInputRef}
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="file-upload"
                                type="file"
                                name='image'
                                onChange={handleImageInputChange} />

                            {formValues.image ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', height: '40px', marginBottom: '1em' }}>
                                    <Typography component='span'>{t('add_image_label')}</Typography>
                                    <label htmlFor="file-upload">
                                        <IconButton
                                            component="span"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '8px',
                                                width: '40px',
                                                height: '40px',
                                                marginLeft: '0.5rem',
                                                backgroundColor: '#f5f5f5',
                                            }}
                                        >
                                            <TaskAltTwoToneIcon sx={{ color: green[500] }} />
                                        </IconButton>
                                    </label>
                                </Box>
                            ) : (
                                <FormControl fullWidth sx={{ margin: '0 0 1em' }} error={!!formErrors.image}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                                        <Typography component='span'>{t('add_image_label')}</Typography>
                                        <label htmlFor="file-upload">
                                            <IconButton
                                                component="span"
                                                sx={{
                                                    borderRadius: '8px',
                                                    width: '40px',
                                                    height: '40px',
                                                    marginLeft: '0.5rem',
                                                    backgroundColor: '#f5f5f5',
                                                    '&:hover': {
                                                        backgroundColor: '#e0e0e0',
                                                    },
                                                }}
                                            >
                                                <AttachFileIcon />
                                            </IconButton>
                                        </label>
                                    </Box>
                                    <FormHelperText>{formErrors.image}</FormHelperText>
                                </FormControl>
                            )}
                            <Typography id="rating-slider" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                                {t('rating_text')}
                                <Rating
                                    value={formValues.rating}
                                    precision={0.5}
                                    max={5} />
                            </Typography>
                            <Slider
                                name='rating'
                                min={1}
                                max={5}
                                step={0.5}
                                aria-label="rating-slider"
                                sx={{ my: 1, color: '#D97706' }}
                                defaultValue={3}
                                onChange={handleSliderChange} />
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                placeholder={t('add_comment_placeholder')}
                                name="comment"
                                value={formValues.comment || ''} // Ensure a default value
                                onChange={(e) =>
                                    setFormValues((prevValues) => ({
                                        ...prevValues,
                                        [e.target.name]: e.target.value,
                                    }))
                                }
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#D97706', // Border color when focused
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#D97706', // Label color when focused
                                    },
                                }}
                            />
                            <DialogActions>
                                <Button onClick={handleDialogClose} sx={{ color: '#D97706' }}>{t('cancel_button')}</Button>
                                <Button type='submit' variant="contained" autoFocus sx={{ backgroundColor: '#D97706', color: '#FFFBEB' }}>
                                    {t('save_button')}
                                </Button>
                            </DialogActions>
                            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={newItemLoading}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog open={openFabDialog} onClose={handleFabDialogClose}>
                    <DialogTitle>{t('filter_results_modal_header')}</DialogTitle>
                    <DialogContent>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedSnacks.beverages}
                                    onChange={handleCheckboxChange}
                                    name="beverages"
                                    sx={{
                                        color: '#D97706', // Default color
                                        '&.Mui-checked': {
                                            color: '#B45309', // Color when checked
                                        },
                                    }}
                                />
                            }
                            label={t('filter_results_modal_checkbox_beverages')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedSnacks.salty}
                                    onChange={handleCheckboxChange}
                                    name="salty"
                                    sx={{
                                        color: '#D97706', // Default color
                                        '&.Mui-checked': {
                                            color: '#B45309', // Color when checked
                                        },
                                    }}
                                />
                            }
                            label={t('filter_results_modal_checkbox_salty')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedSnacks.sweets}
                                    onChange={handleCheckboxChange}
                                    name="sweets"
                                    sx={{
                                        color: '#D97706', // Default color
                                        '&.Mui-checked': {
                                            color: '#B45309', // Color when checked
                                        },
                                    }}
                                />
                            }
                            label={t('filter_results_modal_checkbox_sweets')}
                        />
                    </DialogContent>
                </Dialog>


                <Snackbar open={formSubmitFailure} autoHideDuration={3000} onClose={() => setFormSubmitFailure(false)}>
                    <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
                        {t('field_empty_server_error')}
                    </Alert>
                </Snackbar>
                <Snackbar open={formSubmitSuccess} autoHideDuration={3000} onClose={() => setFormSubmitSuccess(false)}>
                    <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
                        {t('snack_added_success')}
                    </Alert>
                </Snackbar>
                {/* <Snackbar open={snacksNotFound} autoHideDuration={6000} onClose={() => setSnacksNotFound(false)}>
            <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
                {t('login_failure_alert')}
            </Alert>
        </Snackbar> */}
            </Box>
        </>

    );
}
export default MySnacks;

/**********/

// Comment out delete functionality for now. 16.04.2023.
// const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
// Comment out delete functionality for now. 16.04.2023.
// const [cardInformation, setCardInformation] = useState({});

/**********/

// Comment out delete functionality for now. 16.04.2023.
// const handleDeleteButtonClick = () => {
//     setDeleteDialogOpen(true);
// };

// const handleDeleteConfDialogClose = () => {
//     setDeleteDialogOpen(false);
// };

// Comment out delete functionality for now. 16.04.2023.
// const grabCardInfo = (information) => {
//     setCardInformation(information);
//     handleDeleteButtonClick();
// }

// Comment out delete functionality for now. 16.04.2023.
// const handleDelete = async () => {
//     const data = cardInformation;
//     setNewItemLoading(true);
//     try {
//         const token = sessionStorage.getItem('token');

//         const response = await fetch(`${apiUrl}/deleteitem`, {
//             method: 'POST',
//             headers: {
//                 'Content-type': 'application/json',
//                 Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//             },
//             body: JSON.stringify(data)
//         });

//         if (response.ok) {
//             fetchSnacks();
//         } else {
//             console.error('Failed to add item');
//         }
//     } catch (error) {
//         console.error('Error adding item:', error);
//     } finally {
//         handleDeleteConfDialogClose();
//         setNewItemLoading(false); // Set loading to false regardless of success/error
//     }
// };

/**********/

// Comment out delete functionality for now. 16.04.2023.
// <Button size="small" variant='outlined' onClick={() => grabCardInfo({ productName: snack.productName, timeCreated: snack.timeCreated })}>{t('rating_card_delete_button')}</Button>

// Comment out delete functionality for now. 16.04.2023.
// <Dialog
//     open={deleteDialogOpen}
//     onClose={handleDeleteConfDialogClose}
// >
//     <DialogContent>
//         <DialogContentText>
//             {t('delete_conf_text')}
//         </DialogContentText>
//     </DialogContent>
//     <DialogActions>
//         <Button variant='contained' onClick={handleDeleteConfDialogClose}>{t('delete_conf_button_no')}</Button>
//         <Button variant='outlined' onClick={() => handleDelete()}>{t('delete_conf_button_yes')}</Button>
//     </DialogActions>
// </Dialog>


/**********/