// External imports
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid2 } from '@mui/material';

// Internal components
import NavigationBar from './NavigationBar';

const About = () => {
  const { t } = useTranslation();

  const dataToNavigationbar = {
    aboutComponent: '',
  }

  return (
    <div>
      <NavigationBar navigationBarData={dataToNavigationbar} />
      <Grid2
        container
        spacing={2}
        direction="column"
        sx={{
          maxWidth: '1100px',
          margin: 'auto',
          pt: '5em',
          px: '1em',
        }}
      >
        <Grid2>
          <Typography>
            {t('about_page_info')}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography>
            {t('about_page_info_2')}
          </Typography>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default About;
