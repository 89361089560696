// @ts-nocheck

// External imports
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';

// Material UI components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Internal components
import NavigationBar from './NavigationBar';

// API
import apiUrl from '../apiUrl';

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dataToNavigationbar = {
    loginComponent: '',
  }

  const [formValues, setFormValues] = useState({ username: '', password: '', rememberUser: false });

  const [formErrors, setFormErrors] = useState({ username: '', password: '' });
  const [signupMessageVisible, setSignupMessageVisible] = useState(false);
  const [loginFailure, setLoginFailure] = useState(false);
  const [loginToSeeMySnacks, setLoginToSeeMySnacks] = useState(false);
  // const [emailNotConfirmed, setEmailNotConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const { checkLoginStatus } = useAuth(); // Access the checkLoginStatus function from context
  const { isLoggedIn } = useAuth(); // Access the login status from context

  useEffect(() => {
    if (sessionStorage.getItem('signupSuccess')) {
      setSignupMessageVisible(true);
      sessionStorage.removeItem('signupSuccess');
    }

    if (sessionStorage.getItem('loginToSeeMySnacks')) {
      setLoginToSeeMySnacks(true);
      sessionStorage.removeItem('loginToSeeMySnacks');
    }

    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === 'checkbox' ? checked : value,
    }));

    setSignupMessageVisible(false);
  };

  const validateForm = () => {
    const errors = { username: '', password: '' };

    if (formValues.username.trim() === '') {
      errors.username = t('username_empty_error');
    }
    if (formValues.password.trim() === '') {
      errors.password = t('password_empty_error');
    }
    setFormErrors(errors);

    // Return true if there are no errors, indicating that the form is valid
    return Object.values(errors).every((error) => error === '');
  };

  const sendLoginForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (validateForm()) {
        const data = new FormData(event.currentTarget);

        const response = await fetch(`${apiUrl}/login`, {
          method: 'POST',
          body: data,
          credentials: 'include', // Include cookies in the request
        });

        if (response.ok) {
          setLoading(false);
          await checkLoginStatus(); // Verify login status
          sessionStorage.setItem('loginSuccess', 'true'); // Set success flag
          navigate('/'); // Redirect to homepage
        } else if (response.status === 404) {
          setLoginFailure(true); // Show login failure message (user not found)
        } else if (response.status === 400) {
          // User is already logged in, redirect to homepage
          sessionStorage.setItem('alreadyLoggedIn', 'true');
          console.error('User is already logged in.');
          navigate('/'); // Redirect to homepage if already logged in
        } else {
          throw new Error('Error sending form data');
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setLoading(false); // Set loading to false regardless of success/error
    }
  };

  return (
    <div>
      <NavigationBar navigationBarData={dataToNavigationbar} />
      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: { xs: '80px', md: '100px' }
          }}
        >

          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 700,
              fontSize: '2rem',
              color: '#4B5563',
            }}
          >
            {t('log_in_header')}
          </Typography>

          <Box component="form" ref={formRef} onSubmit={sendLoginForm} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={t('login_username_placeholder')}
              name="username"
              autoComplete="username"
              value={formValues.username}
              onChange={handleInputChange}
              error={!!formErrors.username}
              helperText={formErrors.username}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#D97706', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#D97706', // Label color when focused
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password_placeholder')}
              type="password"
              autoComplete="current-password"
              value={formValues.password}
              onChange={handleInputChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#D97706', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#D97706', // Label color when focused
                },
              }}
            />

            <Collapse in={signupMessageVisible}>
              <Alert variant='filled' severity="success" sx={{ marginTop: '1rem' }} onClose={() => setSignupMessageVisible(false)}>
                {t('successful_signup_alert')}
              </Alert>
            </Collapse>

            {/* <Collapse in={emailNotConfirmed}>
              <Alert variant='filled' severity="warning" sx={{ width: '100%', marginTop: '1rem' }} onClose={() => setEmailNotConfirmed(false)}>
                {t('email_not_confirmed_alert')}
              </Alert>
            </Collapse> */}

            {/* "Remember me" functionality TBA */}
            <FormControlLabel control={<Checkbox name="rememberUser" checked={formValues.rememberUser} onChange={handleInputChange} color="primary" />} label={t('remember_me')} />
            {loading ? (
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2, backgroundColor: '#D97706' }}
              >
                <CircularProgress color="inherit" />
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2, backgroundColor: '#D97706' }}
              >
                {t('login_button')}
              </Button>
            )}
            <Grid container direction="column" alignItems="center">
              <Grid item margin={'0.5rem 0 1rem'}>
                <Typography variant="body2">
                  {t('no_account')}
                  {' '}
                  <Link component={RouterLink} to="/signup" sx={{ color: '#D97706', textDecoration: 'none' }}>{t('signup_link')}</Link>
                </Typography>
              </Grid>
              {/* Forgot password functionality TBA */}
              {/* <Grid item>
                <Link component={RouterLink} to="/forgotpassword" variant="body2">{t('forgot_password')}</Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        {/* <Snackbar open={signupMessageVisible} autoHideDuration={3000} onClose={() => setSignupMessageVisible(false)}>
          <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
            {t('successful_signup_alert')}
          </Alert>
        </Snackbar> */}
        <Snackbar open={loginFailure} autoHideDuration={3000} onClose={() => setLoginFailure(false)}>
          <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
            {t('login_failure_alert')}
          </Alert>
        </Snackbar>
        <Snackbar open={loginToSeeMySnacks} autoHideDuration={3000} onClose={() => setLoginToSeeMySnacks(false)}>
          <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
            {t('login_to_see_my_snacks_alert')}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}
