// @ts-nocheck

// External imports
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

// Material UI components
import {
  Typography,
  Snackbar,
  Alert,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  useMediaQuery,
} from '@mui/material';

// Material UI icons
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import FoodBankOutlinedIcon from '@mui/icons-material/FoodBankOutlined';

// Assets
import snackersLogoWhite from '../assets/icons/snackers-logo-white.png';
import FlagFI from '../assets/icons/flag-fi.png';
import FlagEN from '../assets/icons/flag-uk.png';

// Components
import NavigationBar from './NavigationBar';

const Home = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [showLoginSuccessSnackbar, setShowLoginSuccessSnackbar] = useState(false);
  const [showLogoutSuccessSnackbar, setShowLogoutSuccessSnackbar] = useState(false);
  const [loginToChangePassword, setLoginToChangePassword] = useState(false);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const { isLoggedIn } = useAuth(); // Access the login status from context

  useEffect(() => {
    if (sessionStorage.getItem('loginSuccess')) {
      setShowLoginSuccessSnackbar(true);
      sessionStorage.removeItem('loginSuccess')
    }

    if (sessionStorage.getItem('logoutSuccess')) {
      setShowLogoutSuccessSnackbar(true);
      sessionStorage.removeItem('logoutSuccess')
    }
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <NavigationBar navigationBarData={{ homeComponent: '' }} />

      {/* Hero Section with Wave */}
      <Box sx={{
        position: 'relative',
        backgroundImage: 'linear-gradient(#F59E0B, #FBBF24)',
        color: '#FFFBEB',
        pt: 15,
        pb: 15,
      }}>
        <Container maxWidth="lg">

          <Grid
            container
            spacing={4}
            sx={{
              flexDirection: { xs: 'column-reverse', md: 'row' },
              justifyContent: { xs: 'center', md: 'flex-start' },
              alignItems: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '2rem', md: '3.5rem' },
                  mb: 2,
                  textAlign: { xs: 'center', md: 'left' },
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                }}
              >
                {t('hero_title')}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mb: 4,
                  fontWeight: 400,
                  textAlign: { xs: 'center', md: 'left' },
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                }}
              >
                {t('hero_subtitle')}
              </Typography>
              <Box
                sx={{
                  textAlign: { xs: 'center', md: 'left' }, // Align the button responsively
                }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  to="/snacks"
                  size="large"
                  sx={{
                    bgcolor: '#FFFBEB',
                    color: '#D97706',
                    px: 4,
                    py: 1.5,
                    borderRadius: 2,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                >
                  {t('view_snacks_button')}
                </Button>
              </Box>
            </Grid>

            {/* Home page image commented out for now, add later. 24.11.2024. */}
            {/* <Grid item xs={12} md={6}> */}
            {/* Hero image placeholder */}
            {/* <Box
                sx={{
                  height: '400px',
                  width: '100%',
                  display: 'block',
                }}
              >
                <img
                  src="/api/placeholder/600/400"
                  alt="Hero"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </Box> */}
            {/* </Grid> */}
          </Grid>
        </Container>

        {/* Bottom wave */}
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          overflow: 'hidden',
          lineHeight: 0,
          transform: 'rotate(180deg)',
          display: 'block',
        }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            style={{
              position: 'relative',
              display: 'block',
              width: 'calc(100% + 1.3px)',
              height: '100px',
            }}
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              fill="#FFFBEB"
            />
          </svg>
        </Box>
      </Box>

      <Box sx={{ backgroundImage: "linear-gradient(#FFFBEB, #FFFFFF)", py: 10 }}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            sx={{
              alignItems: 'stretch', // Ensure children are the same height
            }}
          >
            {/* Conditional rendering based on login status */}
            {!isLoggedIn ? (
              <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    bgcolor: 'white',
                    p: 4,
                    borderRadius: 2,
                    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <PersonOutlineOutlinedIcon sx={{ width: 48, height: 48, mb: 2 }} />
                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#1F2937' }}>
                      {t('signup_box_header')}
                    </Typography>
                    <Typography sx={{ mb: 3, color: '#4B5563' }}>
                      {t('signup_box_text')}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      component={Link}
                      to="/login"
                      variant="outlined"
                      endIcon={<ArrowForwardOutlinedIcon />}
                      sx={{
                        color: '#B45309',
                        textTransform: 'none',
                        borderRadius: 2,
                        px: 3,
                        border: '1px solid',
                        width: 'auto', // Prevent full-width stretching
                      }}
                    >
                      {t('signup_box_button')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    bgcolor: 'white',
                    p: 4,
                    borderRadius: 2,
                    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <FoodBankOutlinedIcon sx={{ width: 48, height: 48, mb: 2 }} />
                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#1F2937' }}>
                      {t('view_my_snacks_box_header')}
                    </Typography>
                    <Typography sx={{ mb: 3, color: '#4B5563' }}>
                      {t('view_my_snacks_box_text')}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      component={Link}
                      to="/mysnacks"
                      variant="outlined"
                      endIcon={<ArrowForwardOutlinedIcon />}
                      sx={{
                        color: '#B45309',
                        textTransform: 'none',
                        borderRadius: 2,
                        px: 3,
                        border: '1px solid',
                        width: 'auto', // Prevent full-width stretching
                      }}
                    >
                      {t('view_my_snacks_box_button')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* Second Box */}
            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  bgcolor: 'white',
                  p: 4,
                  borderRadius: 2,
                  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <FastfoodOutlinedIcon sx={{ width: 48, height: 48, mb: 2 }} />
                  <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#1F2937' }}>
                    {t('view_snacks_box_header')}
                  </Typography>
                  <Typography sx={{ mb: 3, color: '#4B5563' }}>
                    {t('view_snacks_box_text')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    component={Link}
                    to="/snacks"
                    variant="outlined"
                    endIcon={<ArrowForwardOutlinedIcon />}
                    sx={{
                      color: '#B45309',
                      textTransform: 'none',
                      borderRadius: 2,
                      px: 3,
                      border: '1px solid',
                      width: 'auto', // Prevent full-width stretching
                    }}
                  >
                    {t('view_snacks_button')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>


      {/* Footer Section */}
      <Box
        sx={{
          position: 'relative',
          bgcolor: '#111827',
          color: '#FBBF24',
          py: 5,
        }}
      >
        <Container maxWidth="lg">

          {isMobile ? (
            // Layout for small screens
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>

              {/* Snackers Logo */}
              <Link to="/" sx={{ textDecoration: 'none' }}>
                <Box
                  component="img"
                  src={snackersLogoWhite}
                  alt="Snackers Logo"
                  sx={{ maxWidth: 200 }}
                />
              </Link>

              {/* About Link */}
              <Typography
                sx={{
                  display: 'flex',
                  color: '#FBBF24',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                component={Link}
                to="/about"
              >
                <InfoOutlinedIcon sx={{ marginRight: '4px' }} /> {t('about_link')}
              </Typography>

              {/* Language Links */}
              <Box>
                <Typography variant="body2" sx={{ mb: 1, color: '#9CA3AF' }}>
                  {t('language_selection_caption')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <MenuItem
                    onClick={() => changeLanguage('fi')}
                    sx={{
                      color: '#FBBF24',
                      cursor: 'pointer',
                      p: 0,
                      display: 'flex', // Ensure the flag and text are in a flex container
                      alignItems: 'center', // Align the flag and text vertically
                      gap: 1, // Add spacing between the flag and text
                      minHeight: 0,
                      marginLeft: '2px',
                    }}
                  >
                    <img
                      src={FlagFI}
                      alt="Flag of Finland"
                    />
                    Suomeksi
                  </MenuItem>
                  <MenuItem
                    onClick={() => changeLanguage('en')}
                    sx={{
                      color: '#FBBF24',
                      cursor: 'pointer',
                      p: 0,
                      display: 'flex', // Ensure the flag and text are in a flex container
                      alignItems: 'center', // Align the flag and text vertically
                      gap: 1, // Add spacing between the flag and text
                      minHeight: 0,
                      marginLeft: '2px',
                    }}
                  >
                    <img
                      src={FlagEN}
                      alt="Flag of the UK"
                    />
                    In English
                  </MenuItem>
                </Box>
              </Box>


            </Box>
          ) : (
            // Layout for large screens
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* Left Section: Logo and About Link */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Link to="/" sx={{ textDecoration: 'none' }}>
                  <Box
                    component="img"
                    src={snackersLogoWhite}
                    alt="Snackers Logo"
                    sx={{ maxWidth: 200 }}
                  />
                </Link>

                <Typography
                  sx={{
                    display: 'flex',
                    color: '#FBBF24',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    mt: 2,
                  }}
                  component={Link}
                  to="/about"
                >
                  <InfoOutlinedIcon sx={{ marginRight: '4px' }} /> {t('about_link')}
                </Typography>
              </Box>

              {/* Right Section: Language Links */}
              <Box>
                <Typography variant="body2" sx={{ mb: 1, color: '#9CA3AF', textAlign: 'left' }}>
                  {t('language_selection_caption')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <MenuItem
                    onClick={() => changeLanguage('fi')}
                    sx={{
                      color: '#FBBF24',
                      cursor: 'pointer',
                      p: 0,
                      display: 'flex', // Align items horizontally within each menu item
                      alignItems: 'center',
                      gap: 1, // Add space between the flag and text
                      marginLeft: '2px',
                    }}
                  >
                    <img
                      src={FlagFI}
                      alt="Flag of Finland"
                    />
                    Suomeksi
                  </MenuItem>
                  <MenuItem
                    onClick={() => changeLanguage('en')}
                    sx={{
                      color: '#FBBF24',
                      cursor: 'pointer',
                      p: 0,
                      display: 'flex', // Align items horizontally within each menu item
                      alignItems: 'center',
                      gap: 1, // Add space between the flag and text
                      marginLeft: '2px',
                    }}
                  >
                    <img
                      src={FlagEN}
                      alt="Flag of the UK"
                    />
                    In English
                  </MenuItem>
                </Box>
              </Box>
            </Box>
          )}
        </Container>
      </Box>


      {/* Snackbars */}
      <Snackbar
        open={showLoginSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowLoginSuccessSnackbar(false)}
      >
        <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
          {t('login_success_alert')}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showLogoutSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowLogoutSuccessSnackbar(false)}
      >
        <Alert variant='filled' severity="info" sx={{ width: '100%' }}>
          {t('logout_success_alert')}
        </Alert>
      </Snackbar>

      <Snackbar
        open={loginToChangePassword}
        autoHideDuration={3000}
        onClose={() => setLoginToChangePassword(false)}
      >
        <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
          {t('login_to_change_password_alert')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Home;