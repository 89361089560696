// @ts-nocheck

// External imports
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Material UI components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

// Material UI icons
import TuneIcon from '@mui/icons-material/Tune';

// Material UI styles
import { alpha } from '@mui/material/styles';

// Internal components
import NavigationBar from './NavigationBar';
import apiUrl from '../apiUrl';


const Snacks = () => {
    const { t } = useTranslation();

    const dataToNavigationbar = {
        snacksComponent: '',
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSnacks, setSelectedSnacks] = useState({ beverages: true, salty: true, sweets: true });
    const [snacksData, setSnacksData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [page, setPage] = useState(1); // Track the current page
    const [isMoreData, setIsMoreData] = useState(true); // Track if more data is available
    const itemsPerPage = 16; // Number of snacks per page
    const observerElement = useRef(null); // Ref for IntersectionObserver

    const filteredSnacks = useMemo(() => {
        return snacksData.filter(snack => {
            if (selectedSnacks.beverages && snack.snackType === 'beverage') return true;
            if (selectedSnacks.salty && snack.snackType === 'salty') return true;
            if (selectedSnacks.sweets && snack.snackType === 'sweet') return true;
            return false;
        });
    }, [snacksData, selectedSnacks]);

    const fetchSnacks = useCallback(async (page) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/snacks?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`);
            if (response.ok) {
                const data = await response.json();
                setSnacksData(prev => [...prev, ...data]);
                if (data.length < itemsPerPage) {
                    setIsMoreData(false);
                }
            } else if (response.status === 204) {
                setIsMoreData(false);
            }
        } catch (error) {
            console.error('Error fetching snacks:', error);
        }
        setLoading(false);
    }, [itemsPerPage]);

    // Load snacks for the current page when the component is first mounted or when the page changes
    useEffect(() => {
        fetchSnacks(page);
    }, [page, fetchSnacks]);

    const loadMoreSnacks = useCallback(() => {
        if (isMoreData && !loading) {
            setPage(prevPage => prevPage + 1); // Increment the page to load more data
        }
    }, [isMoreData, loading]);

    // Use IntersectionObserver to detect scrolling to the bottom
    useEffect(() => {
        const currentObserverElement = observerElement.current;
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMoreSnacks(); // Load more snacks when user scrolls to the bottom
            }
        }, { threshold: 1.0 });

        if (currentObserverElement) {
            observer.observe(currentObserverElement);
        }

        return () => {
            if (currentObserverElement) {
                observer.unobserve(currentObserverElement);
            }
        };
    }, [loadMoreSnacks]);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleCheckboxChange = (event) => {
        setSelectedSnacks({
            ...selectedSnacks,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <>
            <NavigationBar navigationBarData={dataToNavigationbar} />
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px', marginTop: { xs: '58px', md: '64px' } }}>
                {loading && page === 1 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Grid container spacing={2} sx={{ maxWidth: '1100px' }}>
                            {filteredSnacks.map((snack, index) => (
                                <Grid item key={index} xs={6} sm={6} md={4}>
                                    <Card>
                                        <CardContent sx={{ padding: 0 }}>
                                            {snack.imageUrl && (
                                                <CardMedia
                                                    component="img"
                                                    sx={{
                                                        height: '300px',
                                                        objectFit: 'cover',    // Ensures the image covers the area without zooming excessively
                                                        width: '100%'          // Ensure it adjusts to the container's width
                                                    }}
                                                    image={`${apiUrl}${snack.imageUrl}`}
                                                    title="Snack Image"
                                                />
                                            )}
                                            <Box sx={{ padding: '1rem 1rem 0 1rem', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                                <Typography fontSize={'0.7rem'} color={alpha('#000000', 0.5)} component="div">
                                                    {t('timestamp_text')} {new Date(snack.timeCreated).toLocaleDateString('en-GB')}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "1",
                                                        WebkitBoxOrient: "vertical",
                                                    }}
                                                >
                                                    {snack.productBrand}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "1",
                                                        WebkitBoxOrient: "vertical",
                                                        lineHeight: 1.2,
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {snack.productName}
                                                </Typography>
                                                {/* Spacer to push content to the bottom */}
                                                <Box sx={{ flexGrow: 1 }} />
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'baseline',
                                                            flexWrap: 'wrap'    // Allow items to wrap when the viewport is narrow
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: '0.8em',
                                                                marginRight: '0.5em'
                                                            }}
                                                            color={alpha('#000000', 0.5)}
                                                            component="div"
                                                        >
                                                            {t('average_rating_info_text')}
                                                        </Typography>

                                                        <Typography component="div" sx={{ minWidth: '25px' }}>
                                                            {snack.averageRating.toFixed(1)}
                                                        </Typography>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',   // Allow wrapping when the viewport is narrow
                                                            alignItems: 'center'  // Ensure vertical alignment stays centered
                                                        }}
                                                    >
                                                        <Rating
                                                            name={`rating-${index}`}
                                                            value={snack.averageRating}
                                                            precision={0.5}
                                                            max={5}
                                                            size="medium"
                                                            readOnly
                                                        />

                                                        <Typography
                                                            sx={{
                                                                marginLeft: '0.3em',
                                                                fontSize: '0.8em'  // Keeps the size as specified, no other styling changes
                                                            }}
                                                            color={alpha('#000000', 0.5)}
                                                            component="div"
                                                        >
                                                            ({snack.reviewsAmount})
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                        <CardActions sx={{ padding: '1rem 1rem 1rem 1rem' }}>
                                            <Button size="small" variant='outlined' sx={{ border: '1px solid', color: '#D97706' }} component={Link} to={`/snackpage/${snack.id}`}>
                                                {t('rating_card_open_button')}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        {/* Infinite Scroll - Loader */}
                        {isMoreData && (
                            <Box ref={observerElement} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                                <CircularProgress />
                            </Box>
                        )}

                        <div style={{ marginBottom: '96px' }} />
                        <Fab onClick={handleDialogOpen} aria-label="add" style={{ position: 'fixed', bottom: '20px', right: '20px', backgroundColor: '#D97706', color: '#FFFBEB' }}>
                            <TuneIcon />
                        </Fab>
                    </Box>
                )}

                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>{t('filter_results_modal_header')}</DialogTitle>
                    <DialogContent>
                        <FormControlLabel
                            control={<Checkbox checked={selectedSnacks.beverages} onChange={handleCheckboxChange} name="beverages" sx={{
                                color: '#D97706', // Default color
                                '&.Mui-checked': {
                                    color: '#B45309', // Color when checked
                                },
                            }} />}
                            label={t('filter_results_modal_checkbox_beverages')}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={selectedSnacks.salty} onChange={handleCheckboxChange} name="salty" sx={{
                                color: '#D97706', // Default color
                                '&.Mui-checked': {
                                    color: '#B45309', // Color when checked
                                },
                            }} />}
                            label={t('filter_results_modal_checkbox_salty')}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={selectedSnacks.sweets} onChange={handleCheckboxChange} name="sweets" sx={{
                                color: '#D97706', // Default color
                                '&.Mui-checked': {
                                    color: '#B45309', // Color when checked
                                },
                            }} />}
                            label={t('filter_results_modal_checkbox_sweets')}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
        </>
    );
};

export default Snacks;
