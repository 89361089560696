const en = {
    // Navigation bar component//

    // Left menu //
    home_button: "Home",
    about_button: "About",
    browse_button: "Browse",

    // Middle header //
    login_header: 'Login',
    create_new_user_header: 'Create an account',
    forgot_password_header: "Recover password",
    user_settings_header: "Settings",
    profile_header: "Profile",
    my_snacks_header: "My snacks",
    search_placeholder: "Search products...",

    // Dropdown menus //
    products_dropdown: "Snacks",
    browse_snacks_header: "Browse snacks",
    snacks_list_item: "Snacks",

    // Right Log in button (large screens) //
    log_in_button: "Log in",

    // Right menu //
    snacks_button: "Browse",
    login_button: "Log in",
    join_now_button: "Join now",
    user_profile_button: "Profile",
    user_settings_button: "Settings",
    my_snacks_button: "My snacks",
    log_out_button: "Log out",
    user_control_section_hi_message: "Hi",
    already_a_member_link: "Already a member? Log in",

    // Home component //
    frontpage_info: "Welcome to Snackers. Before using this website, I recommend checking out the 'About' section, which is accessible from the menu icon at the top left corner.",
    frontpage_choose_category_text: "Choose category:",
    frontpage_button_beverages: "Beverages",
    frontpage_button_salty: "Salty",
    frontpage_button_sweets: "Sweets",
    frontpage_button_all: "All",
    login_success_alert: "Login successful!",
    logout_success_alert: "Logout successful!",
    hero_title: "The Hub for Snackers",
    hero_subtitle: "Need a snack for, say, a movie night?",
    get_started: "Browse snacks",
    signup_box_header: "Do you wish to rate snacks for others to see?",
    signup_box_text: "Log in or create an account",
    signup_box_button: "To login",
    view_my_snacks_box_header: "Do you wish to browse your own snacks?",
    view_my_snacks_box_text: "Proceed to view your snacks",
    view_my_snacks_box_button: "My snacks",
    view_snacks_box_header: "Are you looking to quickly find snacks?",
    view_snacks_box_text: "Proceed to view all snacks",
    view_snacks_button: "Browse snacks",
    logged_in_view_snacks_box_text: "Here you can browse snacks:",
    logged_in_view_snacks_box_button: "Browse",
    logged_in_view_my_snacks_box_text: "Your own snacks can be found here:",
    logged_in_view_my_snacks_box_button: "My snacks",
    already_logged_in_alert: "You are already logged in.",

    // Footer //
    language_selection_caption: "Language:",
    about_link: "About",

    // About component //
    about_page_info: "This website was created as a hobby and for learning purposes. The intention is to add reviews of products that anyone can use when considering what food or drink they would like to purchase, for example, for a movie night. Anyone can browse products and reviews, but adding new ones requires creating an account.",
    about_page_info_2: "The website is currently incomplete, so it may be worthwhile to postpone more active use to the future. However, if you wish, you may still try out the website's features.",

    // Login component //
    log_in_header: "Log in",
    no_account: "New user?",
    signup_link: "Create account.",
    login_username_placeholder: "Username",
    password_placeholder: "Password",
    username_empty_error: "Username required.",
    password_empty_error: "Password required.",
    remember_me: "Remember me",
    forgot_password: "Forgot password?",
    successful_signup_alert: "Account created! You may now log in.",
    login_failure_alert: "Login failed!",
    email_not_confirmed_alert: "Your email address has not been confirmed yet. Please check your email inbox and follow the instructions to verify your email address.",
    login_to_see_my_snacks_alert: "Log in.",
    login_to_change_password_alert: "Log in.",

    // Signup component //
    sign_up_header: "Create an account",
    signup_button: "Create account",
    email_placeholder: "Email (optional)",
    username_placeholder: "Username",
    password_again_placeholder: "Repeat password",
    already_have_account: "Already have an account?",
    login_link: "Log in.",
    username_error: "Username must be at least 2 characters.",
    email_error: "Invalid email.",
    password_error: "Password must be at least 8 characters.",
    password_again_error: "Passwords don't match.",
    signup_failure_alert: "Account could not be created!",
    email_info_text: "Email is only used to reset a forgotten password. Other users won't see this.",
    username_info_text: "Your username will be displayed alongside snacks and reviews that you add.",

    // Forgot password component //
    forgot_password_info: "Did you forget your password? Don't worry, it happens. Insert your account's email address or username below. We'll send instructions on how to reset your password by email.",
    submit_button: "Submit",
    back_to_login_link: "Back to login",

    // User settings component//
    change_password_text: "Change your password here:",
    change_password_password_placeholder: "New password",
    change_password_submit_button: "Submit",
    successful_change_password_alert: "Password changed!",

    // Snacks component //
    timestamp_text: "Added: ",
    rating_card_open_button: "Open",

    // My snacks component //
    add_new_snack_button: "Add review",
    filter_results_modal_header: "Filter results",
    filter_results_modal_checkbox_beverages: "Beverages",
    filter_results_modal_checkbox_salty: "Salty",
    filter_results_modal_checkbox_sweets: "Sweets",
    rating_card_delete_button: "Delete",
    delete_conf_text: "Delete review? This action cannot be undone.",
    delete_conf_button_yes: "Delete",
    delete_conf_button_no: "Back",
    snacks_not_found_info: "Snacks not found.",

    // My snacks add new snack modal //
    save_button: "Save",
    cancel_button: "Cancel",
    add_new_snack_modal_header: "Add a new snack",
    snack_type_placeholder: "Category*",
    snack_brand_placeholder: "Manufacturer or brand",
    snack_name_placeholder: "Snack name",
    rating_text: "Rating: ",
    add_comment_placeholder: "Add a comment... (optional)",
    dropdown_menu_items_beverages: "Beverages",
    dropdown_menu_items_salty: "Salty",
    dropdown_menu_items_sweets: "Sweets",
    add_image_label: "Product image:",
    image_chosen: "Image selected!",
    snack_type_error: "Select a category for the product.",
    snack_brand_error: "Add manufacturer or brand.",
    snack_name_error: "Add a product name.",
    snack_image_error: 'Add an image of the product.',
    field_empty_server_error: 'Fill the required fields.',
    snack_added_success: 'Snack added!',

    // Email confirmation component //
    email_confirmed_text: "Your email is now confirmed and you may log in.",

    // Snackpage component //
    average_rating_info_text: "Average stars:",
    back_button: "Back",
    review_add_success_alert: "Review added!",
    image_filesize_too_big_error: "Image filesize is too large!",
    add_new_review_modal_header: "New review",
    add_optional_comment_placeholder: "Add a comment... (optional)",
    reviews_not_found_info: "Reviews not found.",
}

export default en;
