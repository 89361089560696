// @ts-nocheck

// External imports
import React, { createContext, useState, useEffect, useContext } from 'react';

// API
import apiUrl from '../apiUrl';

// Create a context for authentication
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext); // Custom hook to access the context

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState(''); // Store the user's name

    const checkLoginStatus = async () => {
        try {
            const response = await fetch(`${apiUrl}/auth`, {
                method: 'GET',
                credentials: 'include', // Include cookies in the request
            });

            if (response.ok) {
                const data = await response.json();

                if (data.user) {
                    setIsLoggedIn(true);
                    setUserName(data.name); // Store the user's name
                } else {
                    setIsLoggedIn(false);
                    setUserName(''); // Clear the user's name
                }
            } else {
                console.error('Unexpected response from server', response.status);
                setIsLoggedIn(false);
                setUserName(''); // Clear the user's name
            }
        } catch (error) {
            console.error('Error during authentication:', error);
            setIsLoggedIn(false);
            setUserName(''); // Clear the user's name
        }
    };

    useEffect(() => {
        checkLoginStatus(); // Check login status on mount
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, userName, checkLoginStatus }}>
            {children}
        </AuthContext.Provider>
    );
};

